<template>
  <div class="container">
    <div class="py-2">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button class="nav-link px-2 active" id="nav-app-tab" data-bs-toggle="tab" data-bs-target="#nav-app" type="button" role="tab" aria-controls="nav-app" aria-selected="true">App</button>
          <button class="nav-link px-2" id="nav-log-tab" data-bs-toggle="tab" data-bs-target="#nav-log" type="button" role="tab" aria-controls="nav-log" aria-selected="false">Log</button>
          <button class="nav-link px-2" id="nav-error-tab" data-bs-toggle="tab" data-bs-target="#nav-error" type="button" role="tab" aria-controls="nav-error" aria-selected="false">Errors</button>
          <button class="nav-link px-2" id="nav-localstorage-tab" data-bs-toggle="tab" data-bs-target="#nav-localstorage" type="button" role="tab" aria-controls="nav-localstorage" aria-selected="false">Storage</button>
          <button class="nav-link px-2" id="nav-indexeddb-tab" data-bs-toggle="tab" data-bs-target="#nav-indexeddb" type="button" role="tab" aria-controls="nav-indexeddb" aria-selected="false">IDB</button>
          <button class="nav-link px-2" id="nav-memory-tab" data-bs-toggle="tab" data-bs-target="#nav-memory" type="button" role="tab" aria-controls="nav-memory" aria-selected="false">Memory</button>
        </div>
      </nav>

      <div class="tab-content tiny" id="nav-tabContent">
        <div class="tab-pane fade py-3 active show" id="nav-app" role="tabpanel" aria-labelledby="nav-app-tab">
          <pre>{{application}}</pre>
        </div>
        <div class="tab-pane fade py-3" id="nav-log" role="tabpanel" aria-labelledby="nav-log-tab">
          <pre>{{(app_console ? app_console.log : '')}}</pre>
        </div>
        <div class="tab-pane fade py-3" id="nav-error" role="tabpanel" aria-labelledby="nav-error-tab">
          <pre>{{(app_console ? app_console.error : '')}}</pre>
        </div>
        <div class="tab-pane fade py-3" id="nav-localstorage" role="tabpanel" aria-labelledby="nav-localstorage-tab">
          <pre v-for="key in localstorage" :key="key" :id="'localstorage.' +key" class="mt-0 mb-2" @click="inspectLocalStorage(key)">{{key}}</pre>
        </div>
        <div class="tab-pane fade py-3" id="nav-indexeddb" role="tabpanel" aria-labelledby="nav-indexeddb-tab">
          <pre v-for="key in indexeddb" :key="key" :id="'indexeddb.' +key" class="mt-0 mb-2" @click="inspectIndexedDB(key)">{{key}}</pre>
        </div>
        <div class="tab-pane fade py-3" id="nav-memory" role="tabpanel" aria-labelledby="nav-memory-tab">
          <h6>Memory</h6>
          <pre class="m-0">Total JS heap: {{memory.totalJSHeapSize}}</pre>
          <pre class="m-0">Used JS heap: {{memory.usedJSHeapSize}}</pre>
          <pre class="m-0">Heap size limit: {{memory.jsHeapSizeLimit}}</pre>

          <h6 class="mt-3">Local storage</h6>
          <pre class="m-0">Objects: {{localstorage_info}}</pre>
          <pre class="m-0">Total size: {{localstorage_size}}</pre>

          <h6 class="mt-3">Indexed DB</h6>
          <pre class="m-0">Objects: {{indexeddb_info}}</pre>
          <pre class="m-0">Total size: {{indexeddb_size}}</pre>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="debugModal" tabindex="-1" aria-labelledby="debugModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="debugModalLabel">...</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body overflow-auto" style="max-height:70vh">
          <div class="description tiny p-1 py-3" id="debugModalDescription">...</div>
        </div>
        <div class="modal-footer">
          <button type="button" id="debugModalCancel" class="btn btn-secondary me-auto" @click="removeEntry()">Remove entry</button>
          <button type="button" id="debugModalOK" class="btn btn-primary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
  data() {
    return {
      modal: null,
      type: null, // Remember type info type that we were looking at when trying to delete it
      key: null, // Remember the key of what we were looking at when trying to delete it
      app_console: window.app_console,
      indexeddb: this.indexeddb,
      localstorage: this.app.storage.keys().sort(),
      indexeddb_size: this.indexeddb_size,
      localstorage_size: this.localstorage_size,
      memory: console.memory || {
        totalJSHeapSize: 'N/A',
        usedJSHeapSize: 'N/A',
        jsHeapSizeLimit: 'N/A'
      },
      indexeddb_info: this.indexeddb_info,
      localstorage_info: this.localstorage_info,
    }
  },

  computed: {
    application() {
      // Make a copy of application
      let result = JSON.parse(JSON.stringify(this.app));
      result.qr.QrScanner = '<object>';
      result.qr.modal = '<object>';
      result.camera.modal = '<object>';
      return result;
    }
  },

  beforeCreate() {
    let self = this;

    // Get a list of the stored objects, and do some counting
    window.application.storage.blobs().then( data => {
      self.indexeddb = data;

      self.indexeddb_info = {
        photos: 0,
      }
      for( let object of data ) {
        object = object.split('.');
        switch( object[0] ) {
          case 'photos':
            self.indexeddb_info.photos += 1;
            break;
          default:
            // Ignore for now
        }
      }
    });

    // Get an estimate of the size of indexeddb
    try {
      navigator.storage.estimate().then( (data) => {
        self.indexeddb_size = data });
    } catch(error) {
      console.log(error);
      self.indexeddb_size = 'Property not available';
    }

    // Get an estimate of the size of localstorage (localStorage does UTF-16, blob does UTF-8, so estimate)
    self.localstorage_size = new Blob(Object.values(localStorage)).size *2;

    // Calculate the info for localStorage
    self.localstorage_info = {
      info: 0,
      forms: 0,
      posts: 0,
      other: 0,
    }
    for( let key of self.app.storage.keys() ) {
      key = key.split('.');
      switch( key[0] ) {
        case 'info':
          self.localstorage_info.info += 1;
          break;
        case 'forms':
          self.localstorage_info.forms += 1;
          break;
        case 'created':
          self.localstorage_info.posts += 1;
          break;
        default:
          self.localstorage_info.other += 1;
      }
    }
  },

  mounted() {
    // Initialize the modal
    this.modal = new Modal(document.getElementById('debugModal'), {
      backdrop: true,
    });
  },

  methods: {
    inspectLocalStorage(key) {
      console.log( 'debug.inspectLocalStorage(' +key +')');

      // Load the info
      let content = this.app.storage.get(key);
      if( typeof content == 'object' ) {
        content = JSON.stringify(content, null, 4);
      }
      document.getElementById('debugModalLabel').innerText = key;
      document.getElementById('debugModalDescription').innerText = content;

      // Memorize it so we can delete it if necessary
      this.type = 'localstorage';
      this.key = key;

      // Show the modal with the overview
      this.modal.show();
    },

    inspectIndexedDB(key) {
      console.log( 'debug.inspectIndexedDB(' +key +')');

      // Load the info
      document.getElementById('debugModalLabel').innerText = key;
      document.getElementById('debugModalDescription').innerHTML = '<img class="img-fluid" src="" id="' +key +'" alt="">';
      this.app.storage.loadBlob( key, key );

      // Memorize it so we can delete it if necessary
      this.type = 'indexeddb';
      this.key = key;

      // Show the modal with the overview
      this.modal.show();
    },

    removeEntry() {
      console.log( 'debug.removeEntry');
      let self = this;

      try {
        if( confirm( 'Are you sure?')) {
          switch( this.type ) {
            case 'localstorage':
              self.app.storage.remove( this.key );
              break;
            case 'indexeddb':
              self.app.storage.removeBlob( this.key );
              break;
            default:
              alert( 'Unknown type; expected indexeddb or localstorage' );
              break;
          }
          document.getElementById( this.type +'.' +this.key ).remove();
          this.modal.hide();
        }
      } catch(error) {
        console.error(error);
      }
    }
  }
}
</script>