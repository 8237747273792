<template>
  <div class="login pt-1 pb-5">
    <form autocomplete="off">
      <img class="mx-auto d-block" src="/img/icons/apple-touch-icon-152x152.png" alt="">

      <h3 class="mt-4 mb-0 pe-0 text-center">Login</h3>

      <div v-if="hasServer" class="m-0 p-0 mb-3 text-center small tiny">
        {{this.server}}
      </div>
      <div v-else class="form-floating mt-4 mb-3">
        <input v-model="server" type="text" class="form-control" id="server" autocapitalize="none" autocomplete="nope" autocorrect="off" spellcheck="false" placeholder="Servernavn (uten https://)" v-on:blur="this.server=this.server.toLowerCase().replaceAll(' ','')" />
        <label for="server">Server (zonder https://)</label>
      </div>

      <div class="form-floating mb-3">
        <input v-model="username" type="text" class="form-control" id="username" autocapitalize="none" autocomplete="nope" autocorrect="off" spellcheck="false" placeholder="Brukernavn" />
        <label for="username">Gebruikersnaam</label>
      </div>

      <div class="form-floating mb-3">
        <input v-model="password" type="password" class="form-control" id="password" autocapitalize="none" autocomplete="nope" autocorrect="off" spellcheck="false" placeholder="Tilgangskode" />
        <label for="password">Toegangscode</label>
      </div>

      <p id="login_feedback" class="info tiny">
        {{feedback}}
      </p>

      <button @click="doLogin()" type="button" class="btn btn-sm btn-primary me-3"><i class="fa fa-sign-in-alt"></i> Login</button>
    </form>
  </div>
</template>

<script>
//import router from "@/router";

export default {
  data() {
    return {
      server: '',
      username: '',
      password: '',
      feedback: ''
    }
  },

  computed: {
    hasServer() {
      if( this.app.api.server ) {
        return (this.app.api.server != '');
      } else {
        return false;
      }
    }
  },

  created() {
    this.server = this.app.api.server || '';
    if( this.app.user ) {
      this.username = this.app.user.username || '';
    }
  },

  methods: {
    doLogin() {
      this.feedback = 'Een moment...';
      this.app.login(this.server.toLowerCase().replaceAll(' ',''),this.username,this.password).then(
        (result) => {
          // The result is true (success) or false (unsuccessful)
          if( result ) {
            this.$router.replace('/');
          } else {
            this.feedback = 'Er is een fout opgetreden. Controleer de velden en probeer het opnieuw.';
          }
        },
        (error) => {
          console.error(error);
          this.feedback = 'Er is een fout opgetreden. Controleer de velden en probeer het opnieuw.';
        }
      );
    }
  }
}
</script>